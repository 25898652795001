import { SvgIcon } from '@mui/material';
import React, { FC, ReactNode } from 'react'
import { SubTitle, Title } from '../../atoms';

interface DevStepsProps {
    title: string;
    description: string;
    icon: React.ElementType
}

const DevSteps: FC<DevStepsProps> = (props) => {
    const {title, description, icon} = props
  return (
    <div>
      <SvgIcon component={icon} sx={{width: 60, height: 60}} viewBox="0 0 60 60" />
      <Title>{title}</Title>
      <SubTitle>{description}</SubTitle>
    </div>
  )
}

export default DevSteps
