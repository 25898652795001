import { Box, Container, Stack, useTheme } from '@mui/material'
import React from 'react'
import { SubTitle } from '../../atoms'
import {useTranslation} from "react-i18next"
import { logo } from '../../../assets'

const Footer = () => {
    const {t} = useTranslation("translations")
    const theme = useTheme()
  return (
    <footer>
        <Box sx={{
				background: theme.palette.primary.main,
				pt: 10,
				mt: { md: 0, sm: 2, xs: 2 },
			}}
			>
        <Container maxWidth="lg">
            <Stack direction="row" >

            </Stack>
        <img src={logo} alt="logo" width={180} />

        </Container>
        </Box>
      <Box sx={{ pt: 3, pb: 3 }}>
        <Container maxWidth="lg">
            <Stack direction={"row"} justifyContent="space-around">
            <Box sx={{ mr: 1 }}>
                <SubTitle fontSize='15px' fontWeight={500}>{`${t("copyright")} ${new Date().getFullYear()}, alphawebx.com`}</SubTitle>
            </Box>
                <Stack direction="row" spacing={2}>
                    <SubTitle fontSize='15px' fontWeight={500}>{t('home')}</SubTitle>
                    <SubTitle fontSize='15px' fontWeight={500}>{t('aboutUs')}</SubTitle>
                    <SubTitle fontSize='15px' fontWeight={500}>{t('pricing')}</SubTitle>
                    <SubTitle fontSize='15px' fontWeight={500}>{t('faq')}</SubTitle>
                </Stack>
                </Stack>
		</Container>
		</Box>
    </footer>
  )
}

export default Footer
