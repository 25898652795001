import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages'
import AppRoutes from './app-links.routes'

const AppRouter = () => {
  return (
    <BrowserRouter>
		<Routes>
            <Route path={AppRoutes.HOME} element={<Home />} />
        </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
