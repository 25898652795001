import { Box, Button, Container, Stack } from '@mui/material'
import React from 'react'
import { Title } from '../../atoms'
import {useTranslation} from "react-i18next"
import { ArrowForward } from "@mui/icons-material";
import DefaultButton from '../../atoms/default-button/DefaultButton';

const ThirdSection = () => {
    const {t} = useTranslation("translations")
  return (
    <section className="projects">
      <Box
		sx={{
			    position: 'relative',
			    minHeight: '95vh',
                pt: 6,
			}}
			>
				<Container maxWidth="lg">
                    <Stack direction={"row"} justifyContent="space-between">
                        <Title>{t("viewOurProjects")}</Title>
                        <DefaultButton title={t("viewMore")} endIcon={<ArrowForward />} />
                    </Stack>
                </Container>
                </Box>
                </section>
  )
}

export default ThirdSection
