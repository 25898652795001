import { AppBar, Box, Toolbar, useScrollTrigger, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { logo } from "../../../assets";

interface Props {
	children: React.ReactElement;
 }
 
 function ElevationScroll(props: Props) {
	 const { children } = props;
 
	 const trigger = useScrollTrigger({
		 disableHysteresis: true,
		 threshold: 0,
	 });
 
	 return React.cloneElement(children, {
		 elevation: trigger ? 2 : 0,
	 });
 }

const Navbar = () => {
  return (
	<ElevationScroll>
    <AppBar>
      <Toolbar sx={{
					mt: 1,
					ml: {
						xs: 0, md: 15, lg: 20, xl: 45,
					},
					mr: { xs: 0, lg: 20, xl: 45 },
				}}>
        <Box sx={{ mr: 1 }}>
			<img src={logo} alt="logo" width={180} />
		</Box>
		<Box sx={{ flexGrow: 1 }} />
			<Stack
				direction="row"
				alignItems={"center"}
				spacing={3}
				display={{
					lg: 'flex', xl: 'flex', md: 'none', sm: 'none', xs: 'none',
				}}
			>
				<Typography>
					Home
				</Typography>
				<Typography>
					About us
				</Typography>
				<Typography>
					Pricing
				</Typography>
				<Typography>
					FAQ
				</Typography>
				<Button variant="outlined" color="secondary" sx={{borderRadius: 10}}>
					Contact us
				</Button>
			</Stack>
      </Toolbar>
    </AppBar>
	</ElevationScroll>
  );
};

export default Navbar;
