import { Box, Button, Container, Grid, useTheme } from '@mui/material'
import { ArrowForward } from "@mui/icons-material";
import React from 'react'
import { Space, SubTitle, Title } from '../../atoms'
import { useTranslation } from "react-i18next"
import { DevStepFour, DevStepOne, DevStepThree, DevStepTwo } from '../../../assets/images';
import { DevSteps } from '../../organisms';
import DefaultButton from '../../atoms/default-button/DefaultButton';

const SecondSection = () => {
    const theme = useTheme()
    const {t} = useTranslation("translations")

    const DEV_STEPS = [
      {
        title: t("strategy"),
        desc: t("strategyDesc"),
        icon: DevStepOne
      },
      {
        title: t("wireframing"),
        desc: t("wireframingDesc"),
        icon: DevStepTwo
      },
      {
        title: t("design"),
        desc: t("designDesc"),
        icon: DevStepThree
      },
      {
        title: t("development"),
        desc: t("developmentDesc"),
        icon: DevStepFour
      }
    ]

  return (
    <section className="hero">
      <Box
		sx={{
			    background: theme.palette.info.main,
			    position: 'relative',
			    minHeight: '95vh',
                pt: 6,
                display: "flex",
                flexDirection: "column",
                justifyContent: {md: "center", sm: "flex-start", xs: "flex-start"}
			}}
			>
				<Container maxWidth="lg">
                <Grid container spacing={3}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Title>{t("howWeWork")}</Title>
            <Space height={2} />
            <SubTitle>{t("howWeWorkDescription")}</SubTitle>
            <Space height={2} />
            <DefaultButton title={t("getInTouch")} endIcon={<ArrowForward />} />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{pt: {md: 0, sm: 2, xs: 2}}}>
            <Grid container spacing={3}>
            {
              DEV_STEPS.map((el) => 
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{pt: {md: 0, sm: 2, xs: 2}}}>
              <DevSteps 
              title={el.title} 
              description={el.desc} 
              icon={el.icon} />
              </Grid>
              )
            }
            </Grid>
          </Grid>
          </Grid>
        </Container>
        </Box>
        </section>
  )
}

export default SecondSection
