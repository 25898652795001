import React from 'react'
import { Box, CssBaseline, useTheme } from '@mui/material';
import {Navbar} from "./components/organisms"
import { AppRouter } from './components/routes';

const Main = () => {
    const theme = useTheme()
  return (
    <div>
      <CssBaseline />
      <Navbar />
      <Box sx={{ pt: `${parseInt(String(theme.mixins.toolbar.minHeight), 10)}px`, bgcolor: theme.palette.background.paper }}>
        <AppRouter />
      </Box>
    </div>
  )
}

export default Main
