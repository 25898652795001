import { createTheme, ThemeProvider } from "@mui/material";
import Main from "./Main";

const App = () => {
  const theme = createTheme({
    palette: {
		primary: {
			main: '#383A68',
			contrastText: '#f2f5de',
		},
		secondary: {
			main: '#FCD980',
		},
		error: {
			main: '#e52902',
		},
		success: {
			main: '#93ff96',
		},
		info: {
			main: "#F4F6FC"
		}
	}
  })

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </div>
  );
};

export default App;
