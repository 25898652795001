import { Typography, useTheme } from '@mui/material';
import React from 'react';

interface ITitleProps {
	fontColor?: string;
	fontSize?: string;
	children: string;
	textAlign?: string
}

const Title: React.FC<ITitleProps> = (props) => {
	const theme = useTheme();
	const {
		fontColor = theme.palette.primary.main, textAlign = 'center', fontSize = "40px", children,
	} = props;
	return (
		<Typography sx={{
                color: fontColor, fontSize, lineHeight: '50px'
            }}
		>
			{children}
		</Typography>
	);
};

export default Title;