import { ArrowForward } from "@mui/icons-material";
import { Box, Container, Grid, Stack, useTheme } from "@mui/material";
import React from "react";
import { home_ilustration } from "../../../assets/images";
import { Space, SubTitle, Title } from "../../atoms";
import DefaultButton from "../../atoms/default-button/DefaultButton";

const FirstSection = () => {
  const theme = useTheme()
  return (
    <section className="hero">
      <Box
				sx={{
					background: theme.palette.primary.main,
					position: 'relative',
					minHeight: '95vh',
          pt: 6,
          display: "flex",
          flexDirection: "column",
          justifyContent: {md: "center", sm: "flex-start", xs: "flex-start"}
				}}
			>
				<Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Title fontColor={theme.palette.background.default}>Building steller applications for businesses</Title>
            <Space height={2} />
            <SubTitle fontColor={theme.palette.background.default}>AlphaWebX is the unified platform for change. We accelerate customer's businesses by discovering, designing, and automating their most important processes</SubTitle>
            <Space height={2} />
            <Stack direction="row" justifyContent="space-around">
              <DefaultButton title={"View our work"} color="secondary" variant="contained" />
              <DefaultButton title={"Pricing"} endIcon={<ArrowForward />} variant="outlined" color="secondary" />
            </Stack>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{pt: {md: 0, sm: 2, xs: 2}}}>
            <img src={home_ilustration} width="100%" alt="development illustration"  />
          </Grid>
          </Grid>
        </Container>
        </Box>
    </section>
  );
};

export default FirstSection;
