import { Box, useTheme } from '@mui/material'
import React, { FC } from 'react'

interface SpaceProps {
    height: number
}
const Space: FC<SpaceProps> = (props) => {
    const {height} = props
    const theme = useTheme()
  return (
    <Box sx={{height: theme.spacing(height)}} />
  )
}

export default Space
