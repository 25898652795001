import { Typography, useTheme } from '@mui/material';
import React from 'react';

interface ISubtitleTitleProps {
	fontColor?: string;
	fontSize?: string;
	children: string;
	maxWidth?: string;
	textAlign?: string;
	fontWeight?: number
}

const SubTitle: React.FC<ISubtitleTitleProps> = (props) => {
	const theme = useTheme();
	const {
		fontColor = theme.palette.primary.main,
		fontSize = "18px",
		maxWidth = '600px',
		children,
		fontWeight = '300',
	} = props;
	return (
		<Typography sx={{
			color: fontColor, fontSize, lineHeight: '31.9px', maxWidth, fontWeight,
		}}
		>
			{children}
		</Typography>
	);
};

export default SubTitle;