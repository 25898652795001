enum AppRoutes {
	HOME = '/',
	COMPANY = '/company',
	CAREER = '/careers',
	SUPPORT = '/support',
	CONTACT_US = '/contact-us',
	FAQ = '/faq',

}

export default AppRoutes;