import { Button, ButtonProps } from '@mui/material'
import React, { FC } from 'react'

interface DefaultButtonProps extends ButtonProps {
    title: string
}

const DefaultButton: FC<DefaultButtonProps> = (props) => {
    const {title, sx} = props
  return (
    <Button 
    {...props} 
    sx={{
        ...sx, 
        borderRadius: 50, 
        textTransform: "none",
        }} 
    >
        {title}
    </Button>
  )
}

export default DefaultButton
